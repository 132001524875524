import React from "react";
import { ImLocation2 } from "react-icons/im";
import Aminities from "../Booking/Aminities";
import Hotel_booking_faqs from "../Booking/Hotel_booking_faqs";

const HotelsInfo = ({ hotelDetails }) => {
  return (
    <React.Fragment>
      <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center py-5 gap-4 lg:gap-0 sm:px-5">
        <div className="w-full px-2">
          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
            <h1 className="text-lg lg:text-xl font-bold tracking-wide">
              {hotelDetails?.name}
            </h1>
            <div className="flex justify-end items-center gap-2 mt-2 lg:mt-0">
              <p className="text-gray-600 text-sm lg:text-base tracking-wide">
                Customer ratings
              </p>
              <div className="bg-[#2a7225] rounded-lg text-xs px-3 py-1 text-white font-semibold">
                {hotelDetails?.rating.toString().includes(".")
                  ? hotelDetails?.rating
                  : hotelDetails?.rating + ".0"}
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-between items-start mt-2 gap-2 ">
            <div className="flex">
              <ImLocation2 className="h-5 w-5 text-gray-600 mr-2" />
              <p className="text-sm lg:text-md text-gray-600 tracking-wide">
                {hotelDetails?.address}, {hotelDetails?.city},{" "}
                {hotelDetails?.state} {hotelDetails?.zipcode},{" "}
                {hotelDetails?.country}
              </p>
            </div>
            <div className="flex flex-col item-start md:items-end tracking-wide">
              <p>{hotelDetails?.phone}</p>
              <p>{hotelDetails?.email}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:px-7">
        <div className="col-span-2">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
            <div className="sm:col-span-3 w-full">
              <img
                src={hotelDetails?.images[0]}
                alt="Main Hotel Room"
                className="xl:w-[100%] md:w-[770px] h-[250px] sm:h-[300px] md:h-[320px] object-cover rounded-lg"
              />
            </div>
            <div className="grid grid-rows-2 gap-4  ">
              <img
                src={hotelDetails?.images[1]}
                alt="Hotel Room"
                className=" w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
              />
              <img
                src={hotelDetails?.images[2]}
                alt="Hotel Room"
                className=" w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-4">
            <img
              src={hotelDetails?.images[3]}
              alt="Additional Room 1"
              className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
            />
            <img
              src={hotelDetails?.images[4]}
              alt="Additional Room 2"
              className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
            />
            <img
              src={hotelDetails?.images[5]}
              alt="Additional Room 3"
              className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
            />
            <img
              src={hotelDetails?.images[6]}
              alt="Additional Room 4"
              className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
            />
          </div>
        </div>
      </div>

      <div className="sm:px-7">
        <div className="col-span-2 capitalize">
          <Aminities
            amenities={hotelDetails?.amenities}
            description={hotelDetails?.description}
          />
        </div>
        <div className="flex flex-col md:flex-row gap-4 justify-between mt-4 capitalize">
          <p className="sm:text-base xl:text-md text-[#606060] tracking-wide">
            Check-In Time:{" "}
            <span className="text-[#F94E05]">{hotelDetails?.checkInTime}</span>
          </p>
          <p className="sm:text-base xl:text-md text-[#606060] tracking-wide">
            Check-Out Time:{" "}
            <span className="text-[#F94E05]">{hotelDetails?.checkOutTime}</span>
          </p>
        </div>
        <p className="sm:text-base xl:text-md text-[#f94e05] mt-4 tracking-wide">
          Cancellation Policy:{" "}
          <span className="text-[#606060]">
            {hotelDetails?.cancellationPolicy}
          </span>
        </p>
      </div>

      <div className="sm:px-7">
        <div className="xl:col-span-3 lg:col-span-2">
          <Hotel_booking_faqs />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HotelsInfo;

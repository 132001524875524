import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import fullstar from "../../Assets/user-imgs/hotels/star.png";
import { MdAttachMoney, MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PiWarningCircleDuotone } from "react-icons/pi";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const AllCabs = () => {
  const [cabs, setCabs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedCabId, setSelectedCabId] = useState(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const cabsPerPage = 6;

  const getAllCabs = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/get-all-cabs`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCabs(response?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllCabs();
  }, []);

  const openModal = (id) => {
    setSelectedCabId(id);
    setDeleteModal(true);
  };

  const handleDelete = async (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/cabs/delete-cab/${id}`,
      headers: {},
    };

    try {
      await axios.request(config);
      getAllCabs();
      navigate("/cab");
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteModal(false);
    }
  };

  const handlePagination = (event, value) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(value);
  };

  const SkeletonCard = () => (
    <div className="border rounded-lg shadow-lg overflow-hidden animate-pulse">
      <div className="p-2 bg-gray-100 h-6 w-2/3 mb-4"></div>
      <div className="h-40 bg-gray-200 w-full"></div>
      <div className="p-4">
        <div className="h-6 bg-gray-400 w-1/2 mb-2"></div>
        <div className="flex justify-between items-center text-gray-200 text-sm">
          <div className="h-4 bg-gray-400 w-1/4"></div>
          <div className="h-4 bg-gray-400 w-1/4"></div>
        </div>
      </div>
    </div>
  );

  // Pagination logic
  const indexOfLastCab = currentPage * cabsPerPage;
  const indexOfFirstCab = indexOfLastCab - cabsPerPage;
  const currentCabs = cabs.slice(indexOfFirstCab, indexOfLastCab);
  const totalPages = Math.ceil(cabs.length / cabsPerPage);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 tracking-wide ">
        {loading
          ? Array.from({ length: cabsPerPage }).map((_, index) => (
              <SkeletonCard key={index} />
            ))
          : currentCabs.map((car, index) => (
              <div
                key={index}
                className="border relative bg-cover bg-center rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-all duration-200 bg-slate-100"
              >
                <div className="p-4 flex items-center justify-between bg-gray-100 tracking-wide">
                  <h2 className="text-lg font-semibold text-left capitalize">
                    {car.manufacturer} ({car.type})
                  </h2>
                </div>

                <img
                  src={car.imagesUrls[0]}
                  alt={car.altText}
                  className="w-full object-cover h-40"
                />

                <div className="p-4">
                  <h2 className="text-lg font-semibold text-left mb-2 capitalize">
                    {car.model}
                  </h2>

                  <div className="flex justify-between items-center ">
                    <div className="flex items-center">
                      <img
                        className="h-5 w-auto mr-2"
                        src={fullstar}
                        alt="Rating"
                      />
                      <span className="font-semibold text-sm text-gray-600">
                        {car?.rating.toString().includes(".")
                          ? car?.rating
                          : car?.rating + ".0"}
                      </span>
                    </div>

                    <div className="flex items-center text-orange-600 text-md font-bold">
                      <span>₹ {car.farePerKm} / KM</span>
                    </div>
                  </div>

                  <div className="mt-4 flex justify-between items-center tracking-wide">
                    <button
                      onClick={() => openModal(car._id)}
                      className="py-1 xl:px-4 lg:px-3 px-4 text-sm font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-700"
                    >
                      Delete Cab
                    </button>
                    <button
                      onClick={() => navigate(`/cabs-details/${car?._id}`)}
                      className="py-1 xl:px-4 lg:px-3 px-4 text-sm font-medium text-white bg-slate-500 rounded-lg hover:bg-slate-500"
                    >
                      Details
                    </button>
                  </div>
                </div>
              </div>
            ))}
      </div>

      <div className="flex justify-center items-center">
        <Stack spacing={2} className="mt-8 flex justify-center">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePagination}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </Stack>
      </div>

      {deleteModal && (
        <DelTourModal
          setDeleteModal={setDeleteModal}
          handleDelete={handleDelete}
          roomID={selectedCabId}
        />
      )}
    </div>
  );
};

const DelTourModal = ({ setDeleteModal, handleDelete, roomID }) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteWithLoader = async (id) => {
    setLoading(true);
    await handleDelete(id);
    setLoading(false);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
        <div className="flex justify-center text-red-700 mb-4">
          <PiWarningCircleDuotone size={60} />
        </div>
        <p className="text-center tracking-wide text-lg">
          Are you sure to delete this <span className="font-bold">Cab?</span>
        </p>
        <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
          <button
            onClick={() => setDeleteModal(false)}
            className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
          >
            Cancel
          </button>
          <button
            className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700 flex items-center justify-center"
            onClick={() => handleDeleteWithLoader(roomID)}
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center gap-2">Deleting...</span>
            ) : (
              "Yes, Delete"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllCabs;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import fullstar from "../../Assets/user-imgs/hotels/star.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const AllHotels = () => {
  const ITEMS_PER_PAGE = 9;
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [assignRoomLoading, setAssignRoomLoading] = useState(false);
  const [htl_id, setHtl_id] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [roomData, setRoomData] = useState({
    name: "",
    price: "",
    bedCount: 0,
    roomType: "Single",
    description: "",
  });
  const navigate = useNavigate();

  const resentModalState = {
    name: "",
    price: "",
    bedCount: 0,
    roomType: "Single",
    description: "",
  };

  // Fetch hotels data
  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/hotels/get-all-hotels`
        );
        setHotels(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch hotels data");
        setLoading(false);
      }
    };

    fetchHotels();
  }, []);

  const totalPages = Math.ceil(hotels.length / ITEMS_PER_PAGE);
  const hotelsToDisplay = hotels.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCreateRoom = () => {
    let data = JSON.stringify({
      name: roomData?.name,
      type: roomData?.roomType,
      description: roomData?.description,
      bedCount: Number(roomData?.bedCount),
      price: Number(roomData?.price),
      room_amenities: [],
      images: [],
      availability: [],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/rooms/create-room`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    setAssignRoomLoading(true);
    axios
      .request(config)
      .then((response) => {
        handleAssignRoom(response?.data?._id);
      })
      .catch((error) => {
        console.error(error);
        setAssignRoomLoading(false);
      });
  };

  const handleAssignRoom = (room_id) => {
    let data = JSON.stringify({
      hotelId: htl_id,
      roomIds: [room_id],
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/hotels/assign-rooms-to-hotel`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log("response", response.data);
        setAssignRoomLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setAssignRoomLoading(false);
      });

    setShowModal(false);
    setRoomData(resentModalState);
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {Array.from({ length: 6 }).map((_, index) => (
          <SkeletonLoader key={index} />
        ))}
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="min-h-screen">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {hotelsToDisplay.map((hotel) => (
          <div
            key={hotel?.id}
            className="relative bg-cover bg-center rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-all duration-200"
            style={{ backgroundImage: `url(${hotel?.images[0]})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="relative z-10 p-6">
              <h2 className="text-xl font-semibold text-white tracking-wider">
                {hotel?.name}
              </h2>
              <p className="text-white text-sm mt-2 tracking-wider capitalize text-justify">
                {hotel?.description?.length > 100
                  ? hotel?.description?.substring(0, 100) + "..."
                  : hotel?.description}
              </p>
              <div className="mt-4">
                <p className="text-white font-semibold text-lg tracking-wider">
                  {hotel?.price}
                </p>
                <div className="flex items-center mt-2">
                  <img
                    className="h-5 w-auto mr-2"
                    src={fullstar}
                    alt="Rating"
                  />
                  <span className="text-white text-sm tracking-wider">
                    {hotel?.rating.toString().includes(".")
                      ? hotel?.rating
                      : hotel?.rating + ".0"}
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-4 tracking-wide">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowModal(true);
                    setHtl_id(hotel?._id);
                  }}
                  className="py-1 xl:px-4 lg:px-3 px-4 text-sm font-medium text-white bg-orange-500 rounded-lg hover:bg-orange-700"
                >
                  Assign Room
                </button>
                <button
                  onClick={() => navigate(`/admin-hotel-details/${hotel?._id}`)}
                  className="py-1 xl:px-4 lg:px-3 px-4 text-sm font-medium text-white bg-slate-400 rounded-lg hover:bg-slate-500"
                >
                  Details
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center">
        <div className="flex justify-end items-center mt-6">
          <Stack spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              variant="outlined"
              color="primary"
              shape="rounded"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      {showModal && (
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          roomData={roomData}
          setRoomData={setRoomData}
          handleCreateRoom={handleCreateRoom}
          assignRoomLoading={assignRoomLoading}
        />
      )}
    </div>
  );
};

export default AllHotels;

// modal
const Modal = ({
  showModal,
  setShowModal,
  roomData,
  setRoomData,
  handleCreateRoom,
  assignRoomLoading,
}) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Assign Room</h2>
      <input
        type="text"
        name="name"
        value={roomData.name}
        onChange={(e) => setRoomData({ ...roomData, name: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Room Name"
      />
      <input
        type="text"
        name="price"
        value={roomData.price}
        onChange={(e) => setRoomData({ ...roomData, price: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Price"
      />
      <input
        type="number"
        min={1}
        name="bedCount"
        value={roomData.bedCount}
        onChange={(e) => setRoomData({ ...roomData, bedCount: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Bed Count"
      />
      <select
        name="roomType"
        value={roomData.roomType}
        onChange={(e) => setRoomData({ ...roomData, roomType: e.target.value })}
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
      >
        <option value="Single">Single</option>
        <option value="Double">Double</option>
        <option value="Suite">Suite</option>
      </select>
      <textarea
        name="description"
        value={roomData.description}
        onChange={(e) =>
          setRoomData({ ...roomData, description: e.target.value })
        }
        className="w-full p-2 border border-gray-300 rounded-md mb-4"
        placeholder="Description"
      />
      <div className="flex justify-end space-x-4">
        <button
          onClick={() => setShowModal(false)}
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          onClick={handleCreateRoom}
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
        >
          {assignRoomLoading ? "Please Wait..." : "Assign"}
        </button>
      </div>
    </div>
  </div>
);

const SkeletonLoader = () => (
  <div className="bg-gray-300 w-auto h-auto rounded-lg shadow-md overflow-hidden animate-pulse">
    <div className="p-6">
      <div className="h-6 bg-gray-400 rounded mb-4"></div>
      <div className="h-4 bg-gray-400 rounded mb-2 w-3/4"></div>
      <div className="mt-4">
        <div className="h-6 bg-gray-400 rounded w-1/2 mb-2"></div>
        <div className="flex items-center mt-2">
          <div className="h-4 w-4 bg-yellow-400 rounded-full mr-2"></div>
          <div className="h-4 bg-gray-400 rounded w-1/4"></div>
        </div>
      </div>
    </div>
  </div>
);

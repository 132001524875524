import React from "react";
import { GoDotFill } from "react-icons/go";
import PrivacyImage from "../../Assets/Logos/PrivacyAndPolicy.png";
import { FaRegHandPointRight } from "react-icons/fa6";

export default function PrivacyPolicy() {
  return (
    <div>
      <React.Fragment>
        <div className="flex-grow relative mb-8">
          <div className="relative">
            <img className="w-full h-auto" src={PrivacyImage} alt="Tourimg" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white md:text-4xl text-2xl font-semibold">
              Privacy & Pricing Policy
            </div>
          </div>
        </div>
      </React.Fragment>

      <div className="py-7 px-4 sm:px-12 xl:px-20">
        <h2 className="text-4xl font-semibold mb-4">Privacy Policy</h2>

        <div className="space-y-6">
          <div>
            <h3 className="font-semibold mb-2 text-2xl">Who we are</h3>
            <p className="text-gray-700 text-lg">
              Our website address is:{" "}
              <a
                href="https://weekendbhraman.com"
                className="text-blue-500 underline"
              >
                weekendlibrarian.com
              </a>
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">Comments</h3>
            <p className="text-gray-700 text-lg">
              When visitors leave comments on the site we collect the data shown
              in the comments form, and also the visitor's IP address and
              browser user agent string to help spam detection.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">Media</h3>
            <p className="text-gray-700 text-lg">
              If you upload images to the website, you should avoid uploading
              images with embedded location data (EXIF GPS) included. Visitors
              to the website can download and extract any location data from
              images on the website.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">Cookies</h3>
            <p className="text-gray-700 text-lg">
              If you leave a comment on our site, you may opt-in to saving your
              name, email address and website in cookies. These are for your
              convenience so that you do not have to fill in your details again
              when you leave another comment. These cookies will last for one
              year.
            </p>
            <p className="text-gray-700 mt-2 text-lg">
              If you visit our login page, we will set a temporary cookie to
              determine if your browser accepts cookies. This cookie contains no
              personal data and is discarded when you close your browser.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">
              Embedded content from other websites
            </h3>
            <p className="text-gray-700 text-lg">
              Articles on this site may include embedded content (e.g. videos,
              images, articles, etc.). Embedded content from other websites
              behaves in the exact same way as if the visitor has visited the
              other website.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">
              Who we share your data with
            </h3>
            <p className="text-gray-700 text-lg">
              If you request a password reset, your IP address will be included
              in the reset email.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">
              How long we retain your data
            </h3>
            <p className="text-gray-700 text-lg">
              If you leave a comment, the comment and its metadata are retained
              indefinitely. This is so we can recognize and approve any
              follow-up comments automatically instead of holding them in a
              moderation queue.
            </p>
          </div>

          <div>
            <h3 className="font-semibold mb-2 text-2xl">
              What rights you have over your data
            </h3>
            <p className="text-gray-700 text-lg">
              If you have an account on this site, or have left comments, you
              can request to receive an exported file of the personal data we
              hold about you. You can also request that we erase any personal
              data we hold about you. This does not include any data we are
              obliged to keep for administrative, legal, or security purposes.
            </p>
          </div>
          <div>
            <h3 className="font-semibold mb-2 text-2xl">
              Where your data is sent
            </h3>
            <p className="text-gray-700 text-lg">
              Visitor comments may be checked through an automated spam
              detection service.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { GoDotFill } from "react-icons/go";
import refundPolicy from "../../Assets/Logos/RefundPolicy.png";

export default function RefundPolicy() {
  return (
    <div>
      <React.Fragment>
        <div className="flex-grow relative mb-8">
          <div className="relative">
            <img className="w-full h-auto" src={refundPolicy} alt="Tourimg" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white md:text-4xl text-lg font-semibold">
              Refund & Cancelation Policy
            </div>
          </div>
        </div>
      </React.Fragment>

      <div className="container mx-auto p-8">
        <div>
          <div className="flex items-center">
            <div
              // style={{ fontFamily: "cursive" }}
              className=" sm:text-4xl text-2xl font-bold text-start mb-4"
            >
              Refund Policy
            </div>
          </div>

          <p className="text-[#051836] leading-relaxed text-justify text-lg">
            Weekend Bhraman Tour Planner ( https://weekendbhraman.com) will
            process the refund only after getting a cancellation request/claim
            from the customer/travel agent/corporate company through the
            mybooking section or email. Refund against the cancellation of air
            tickets will be credited directly in the customer’s mode of payment
            after receiving the refund amount from the airline. Weekend Bhraman
            Travels will process the refund within 72 only after receiving it
            from the concerned Airlines/hotel/transporter/other suppliers. Due
            to Banking Procedures, Refund on credit cards may take a duration of
            72 hours while it may take 7 days in case of net banking
            transactions.
          </p>
        </div>

        <div className="mt-8">
          <div
            // style={{ fontFamily: "cursive" }}
            className=" sm:text-4xl text-2xl font-bold text-start w-full mb-4"
          >
            General Terms of use & Service
          </div>

          <p className="text-[#051836] leading-relaxed text-justify">
            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                Payment will be acceptable only with the mentioned details or by
                cash.
              </p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>Please read our terms and conditions before booking.</p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                If you cancel your trip before 30 days, we will not charge you
                any retention amount.
              </p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                From the booking date till 30 days prior to the arrival date,
                30% of the package cost is chargeable.
              </p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                From 30 days to 15 days prior to the arrival date, 50% of the
                package cost is chargeable.
              </p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                From 15 days to 7 days prior to the arrival date, 75% of the
                package cost is chargeable.
              </p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                For cancellations made within 5 days prior to the arrival date,
                100% of the package cost is chargeable.
              </p>
            </div>

            <div className="flex gap-2 justify-start items-center mt-2 text-lg">
              <div>
                <GoDotFill size={15} />
              </div>
              <p>
                Above are the cancellation rules, but we will put our best
                possible effort to minimize the cancellation charges.
              </p>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useState } from "react";
import { FaStar, FaStarHalf, FaRegStar } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { IoLocationOutline } from "react-icons/io5";
import { MdPhone } from "react-icons/md";
import htlImg from "../../Assets/Common/htl.jpg";
import { TbAirConditioningDisabled } from "react-icons/tb";
import { MdOutlineTv } from "react-icons/md";
import { FaWifi } from "react-icons/fa6";
import { LuBath } from "react-icons/lu";
import { MdOutlineIron } from "react-icons/md";
import { MdBalcony } from "react-icons/md";
import { TbClock24 } from "react-icons/tb";
import { PiSwimmingPoolDuotone } from "react-icons/pi";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import ClientDetailsSkeliton from "./ClientDetailsSkeliton";
import SkeletonLoaderForHistory from "./SkeletonLoaderForHistory";

const ClientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("overview");
  const [databyId, setDatabyId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    hotelSetailsbyId();
  }, []);

  const hotelSetailsbyId = () => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/hotels/hotel-by-id/${id}`,
      headers: {},
      data: data,
    };
    setIsLoading(true);

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response?.data));
        setDatabyId(response?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  console.log("datas", databyId);

  return (
    <>
      <div>
        {isLoading ? (
          <ClientDetailsSkeliton />
        ) : (
          <>
            <div className="mt-5 font-be-vietnam px-6 mb-6">
              <div className="flex justify-between  items-start sm:flex-row flex-col gap-3 sm:gap-0">
                <div className="flex justify-start items-start flex-col gap-2 ">
                  <p className="text-2xl flex justify-start items-center gap-2 font-semibold">
                    <IoIosArrowBack size={28} onClick={() => navigate(-1)} />
                    {databyId?.name}
                  </p>
                  <p className="text-md flex justify-start items-center gap-3 font-medium text-justify pl-1">
                    <IoLocationOutline size={20} />
                    {databyId?.address}
                  </p>
                  <p className="text-md flex justify-start items-center gap-3 font-medium text-justify pl-1.5">
                    <MdPhone size={20} />
                    {databyId?.phone}
                  </p>
                </div>
                <div className="flex sm:justify-center justify-start sm:items-center flex-col gap-1">
                  <p className="text-lg font-medium">
                    Rating:{databyId?.rating}
                  </p>
                  <div className="flex justify-start items-center gap-2 text-yellow-400">
                    {Array?.from({ length: 5 }, (_, i) => {
                      if (i < Math?.floor(databyId?.rating))
                        return <FaStar key={i} />;
                      if (i < databyId?.rating) return <FaStarHalf key={i} />;
                      return <FaRegStar key={i} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="mt-5">
                {databyId ? (
                  <img
                    src={databyId?.images[0]}
                    alt=""
                    className="h-[60vh] w-full rounded-md"
                  />
                ) : null}
                {/* <img
            src={databyId?.images[0]}
            alt=""
            className="h-[60vh] w-full rounded-md"
          /> */}
              </div>
              <div>
                <div className="flex justify-start sm:items-center items-start sm:gap-7 gap-4 mt-5 sm:text-[15px] text-sm font-medium text-gray-600 sm:flex-row flex-col">
                  <button
                    className={`hover:text-[#55be42] hover:underline ${
                      activeSection === "overview" ? "text-[#55be42]" : ""
                    }`}
                    onClick={() => setActiveSection("overview")}
                  >
                    Hotel Overview
                  </button>
                  <button
                    className={`hover:text-[#55be42] hover:underline ${
                      activeSection === "photos" ? "text-[#55be42]" : ""
                    }`}
                    onClick={() => setActiveSection("photos")}
                  >
                    Photo
                  </button>
                </div>
                {activeSection === "overview" && (
                  <div>
                    <div className=" w-full mt-7">
                      <p className="text-xl font-semibold">Hotel Description</p>
                      <p className="text-justify tracking-wide text-md leading-relaxed mt-2">
                        {databyId?.description}
                      </p>
                    </div>

                    <div className="w-full mt-7">
                      <p className="text-xl font-semibold">Hotel Aminaties</p>
                      <div className="flex justify-between mt-3">
                        {databyId?.amenities?.map((i) => {
                          return (
                            <>
                              <div className=" flex justify-center items-center gap-2">
                                <FaArrowRight />
                                <p>{i}</p>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {activeSection === "photos" && (
                  <div className="mt-5">
                    <p className="text-xl font-semibold">Photos Section</p>

                    <div className="col-span-2 mt-4">
                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4">
                        <div className="sm:col-span-3 w-full">
                          <img
                            src={databyId?.images[0]}
                            alt="Main Hotel Room"
                            className="xl:w-[100%] md:w-[770px] h-[250px] sm:h-[300px] md:h-[320px] object-cover rounded-lg"
                          />
                        </div>
                        <div className="grid grid-rows-2 gap-4  ">
                          <img
                            src={databyId?.images[1]}
                            alt="Hotel Room"
                            className=" w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                          />
                          <img
                            src={databyId?.images[2]}
                            alt="Hotel Room"
                            className=" w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                          />
                        </div>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-4">
                        <img
                          src={databyId?.images[3]}
                          alt="Additional Room 1"
                          className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                        />
                        <img
                          src={databyId?.images[4]}
                          alt="Additional Room 2"
                          className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                        />
                        <img
                          src={databyId?.images[5]}
                          alt="Additional Room 3"
                          className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                        />
                        <img
                          src={databyId?.images[6]}
                          alt="Additional Room 4"
                          className="w-full h-[120px] sm:h-[140px] md:h-[150px] object-cover rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ClientDetails;

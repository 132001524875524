import React, { useState } from "react";
import { useEffect } from "react";
import SimpleBanner from "../../components/common/SimpleBanner";
import Carbanner from "../../Assets/Common/CarBooking.png";
import Details from "../../components/Booking/Details";
import { useNavigate } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import toast from "react-hot-toast";
import axios from "axios";
import { PiWarningCircleDuotone } from "react-icons/pi";

const CabBookingForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    state: "",
    mobile: "",
    address: "",
    countryCode: "",
    BookingFor: "own",
    Travelling: "no",
  });
  const [errors, setErrors] = useState({});
  const [preFormData, setPreFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [uponModal, setUponModal] = useState(false);

  const stateData = [
    { state: "Odisha" },
    { state: "Chhattisgarh" },
    { state: "West Bengal" },
    { state: "Bihar" },
  ];

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("formData"));
    setPreFormData(data);
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim())
      newErrors.lastName = "Last name is required.";
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "A valid email is required.";
    }
    if (!formData.mobile.trim() || !/^\d{10}$/.test(formData.mobile)) {
      newErrors.mobile = "A valid 10-digit mobile number is required.";
    }
    if (!formData.address.trim()) newErrors.address = "Address is required.";
    if (!formData.countryCode)
      newErrors.countryCode = "Country/Region is required.";
    if (!formData.state) newErrors.state = "State is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const cabBookingData = () => {
    const data = JSON.stringify({
      fullName: `${formData.firstName} ${formData.lastName}`,
      address: formData.address,
      phoneNo: formData.mobile,
      email: formData.email,
      country: formData.countryCode,
      state: formData.state,
      password: "mypassword456",
      totalPersons: 2,
      children: 0,
      adults: 2,
      bookingType: "cab",
      bookingFor: formData.BookingFor,
      professionalWorkBooking: true,

      bookingDetails: {
        totalPrice: preFormData?.farePerKm || 0,
        paymentMode: "offline",
        paymentStatus: "pay later",
        advancePayment: 0,
        cabDetails: {
          cabId: preFormData?.cabID,
          cabName: preFormData?.cabName,
          travelStartPlace: preFormData?.address,
          travelEndPlace: preFormData?.destination,
          bookingDates: [preFormData?.startDate, preFormData?.endDate],
        },
      },
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/bookings/create-booking`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    setIsLoading(true);

    axios
      .request(config)
      .then((response) => {
        // toast.success("Cab booking successful!");
        setAllData(response?.data?.data);
        navigate("/booking-successful/cab");
        window.scrollTo({ top: 0, behavior: "smooth" });
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Booking failed. Please try again.");
        setLoader(false);
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      cabBookingData();
    } else {
      setTimeout(() => setErrors({}), 3000);
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="bg-white">
      {/* Banner Section */}
      <div>
        <SimpleBanner
          image={Carbanner}
          name="Cab Booking"
          path="WB Group / Cab Booking"
        />
      </div>

      <div className="grid lg:grid-cols-8 grid-cols-1 font-be-vietnam  lg:px-12 px-4 sm:px-7 tracking-wide gap-4">
        <div className="lg:col-span-2 col-span-1 w-full">
          <div className="flex flex-col justify-center  items-center md:gap-3 lg:gap-0 md:flex-row lg:flex-col w-full">
            <div className="bg-[#f3efef] rounded-lg p-3 shadow-md w-full">
              <div className="flex flex-col lg:justify-start justify-between items-start gap-2 text-md w-full">
                <div className="flex justify-start items-center gap-2 w-full">
                  <p className="text-lg text-orange-600 font-semibold capitalize">
                    Customers rating
                  </p>
                  <div className="bg-[#2a7225] rounded-lg text-xs px-3 py-1 text-white font-semibold">
                    {preFormData?.cabRating.toString().includes(".")
                      ? preFormData?.cabRating
                      : preFormData?.cabRating + ".0"}
                  </div>
                </div>
                <p className="text-xl font-semibold">
                  {preFormData?.cabName} ({preFormData?.carType})
                </p>
                <p className="text-[#676767] capitalize">
                  Cab Address:{" "}
                  {preFormData?.cabState + preFormData?.cabCity || "N/A"}
                </p>
                <div className="flex justify-start items-center gap-3 text-[#676767] capitalize">
                  <p>Colour:{preFormData?.cabColor || "N/A"}</p>
                </div>
              </div>
            </div>

            <div className="lg:mt-2 flex flex-col justify-center items-start gap-2 text-md   rounded-lg lg:p-3 w-full">
              <h1 className="text-xl font-semibold  capitalize">
                Booking details:
              </h1>

              <div className="flex flex-col justify-start items-start gap-4">
                {/* Room Selection Summary */}
                <div className="flex justify-between items-start gap-4 flex-col">
                  <div>
                    <p className="font-semibold text-md capitalize">
                      From:{preFormData?.address}
                      <span className="font-semibold text-blue-600">
                        {/* {detailsData?.selectedRooms?.length} Room(s) */}
                      </span>
                    </p>
                    <p className="font-semibold text-md mt-2 capitalize">
                      Destiantion:{preFormData?.destination}
                      <span className="font-semibold text-blue-600">
                        {/* {detailsData?.selectedRooms?.length} Room(s) */}
                      </span>
                    </p>
                    <div className="flex items-center gap-2 text-[#212121] mt-2">
                      <p className="text-sm font-semibold">
                        Booking Date:{preFormData?.startDate} to{" "}
                        {preFormData?.endDate}
                      </p>
                      <p className="text-sm font-normal">
                        {/* {detailsData?.hotel?.checkInTime} */}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Change Selection Button */}
                <div className="lg:pt-3">
                  <div
                    onClick={() => {
                      localStorage.clear();
                      navigate(-1);
                    }}
                    className="text-[#2D87D0] hover:text-[#423eb4] font-semibold underline hover:cursor-pointer"
                  >
                    Modify Your Selection
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-6">
          <div>
            <form
              onSubmit={handleFormSubmit}
              className="pb-5 lg:pl-4 font-be-vietnam"
            >
              <h2 className="font-semibold text-xl mb-6">
                Provide Your Details
              </h2>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* First Name */}
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-md font-semibold text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className={`mt-1 block w-full h-9 border-[1px] px-2 ${
                      errors.firstName ? "border-red-500" : "border-black"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>

                {/* Last Name */}
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className={`mt-1 block w-full h-9 border-[1px] px-2 ${
                      errors.lastName ? "border-red-500" : "border-black"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>

                {/* Email Address */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`mt-1 block w-full h-9 border-[1px] px-2 ${
                      errors.email ? "border-red-500" : "border-black"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                  <p className="mt-2 text-xs text-gray-500">
                    Confirmation email goes to this address
                  </p>
                </div>

                {/* Mobile Number */}
                <div>
                  <label
                    htmlFor="mobile"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Mobile Number
                  </label>
                  <div className="flex items-center mt-1">
                    <select
                      id="countryCode"
                      name="countryCode"
                      className="border-black h-9 md:px-2 px-0 border-[1px] rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mr-1"
                    >
                      <option value="IN">IN +91</option>
                      <option value="US">US +1</option>
                      <option value="UK">UK +44</option>
                    </select>
                    <input
                      type="tel"
                      id="mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={(e) =>
                        setFormData({ ...formData, mobile: e.target.value })
                      }
                      className="block w-full h-9 border-[1px] px-2 border-black rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ml-1"
                      placeholder="Enter mobile number"
                    />
                  </div>
                  {errors.mobile && (
                    <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
                  )}
                  <p className="mt-2 text-xs text-gray-500">
                    Needed by the property to validate your booking
                  </p>
                </div>

                <div>
                  <label
                    htmlFor="address"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={`mt-1 block w-full h-9 border-[1px] px-2 ${
                      errors.address ? "border-red-500" : "border-black"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  />
                  {errors.address && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.address}
                    </p>
                  )}
                </div>

                {/* Country/Region */}
                <div className="md:col-span-1">
                  <label
                    htmlFor="country"
                    className="block text-md font-semibold text-gray-700"
                  >
                    Country/Region
                  </label>
                  <select
                    id="country"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                    className={`mt-1 block px-2 w-full h-9 border-[1px] ${
                      errors.countryCode ? "border-red-500" : "border-black"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  >
                    <option value="">Select a country</option>
                    <option value="IN">India</option>
                    <option value="US">United States</option>
                    <option value="UK">United Kingdom</option>
                  </select>
                  {errors.countryCode && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.countryCode}
                    </p>
                  )}
                </div>
                <div className="md:col-span-1">
                  <label
                    htmlFor="state"
                    className="block text-md font-semibold text-gray-700"
                  >
                    State
                  </label>
                  <select
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className={`mt-1 block px-2 w-full h-9 border-[1px] ${
                      errors.state ? "border-red-500" : "border-black"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                  >
                    <option value="">Select Your state</option>
                    {stateData.map((i, index) => (
                      <option key={index} value={i.state}>
                        {i.state}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="text-red-500 text-sm mt-1">{errors.state}</p>
                  )}
                </div>

                {/* Who are you booking for */}
                <div>
                  <label htmlFor="" className="capitalize">
                    Who are you booking for?
                  </label>
                  <div className="flex flex-col gap-2 pt-2">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="BookingFor"
                        value="own"
                        checked={formData.BookingFor === "own"}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      I am the main guest
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="BookingFor"
                        value="for someone"
                        checked={formData.BookingFor === "for someone"}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Booking is for someone else
                    </label>
                  </div>
                </div>

                {/* Are you traveling for work */}
                <div>
                  <label htmlFor="" className="capitalize">
                    Are you traveling for work?
                  </label>
                  <div className="flex gap-4 pt-2">
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="Travelling"
                        value="yes"
                        checked={formData.Travelling === "yes"}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      Yes
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="Travelling"
                        value="no"
                        checked={formData.Travelling === "no"}
                        onChange={handleChange}
                        className="mr-2"
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>

              {/* Submit Buttons */}
              <div className="w-full pt-6">
                <div className="flex justify-end items-center flex-col md:flex-row gap-4">
                  <button
                    type="button"
                    onClick={() => setUponModal(true)}
                    className="px-4 py-2 w-full sm:w-auto text-[#2D87D0] text-md border-[1px] border-[#2D87D0] rounded-lg"
                  >
                    Cancel Booking
                  </button>

                  <button
                    type="submit"
                    className="px-4 py-2 w-full sm:w-auto bg-[#2D87D0] text-md border-[1px] border-[#2D87D0] text-white rounded-lg flex justify-center items-center gap-2"
                  >
                    {loader ? (
                      "Please wait..."
                    ) : (
                      <>
                        Confirm Booking <MdArrowForwardIos />
                      </>
                    )}
                  </button>
                </div>

                {uponModal && (
                  <DelTourModal
                    uponModal={uponModal}
                    setUponModal={setUponModal}
                    navigate={navigate}
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabBookingForm;

const SkeletonLoader = () => {
  return (
    <>
      <div className="flex justify-between items-start lg:flex-row flex-col gap-6 px-4 sm:px-0 lg:px-3 bg-white animate-pulse">
        <div className=" lg:w-[30%] rounded-lg w-full  ">
          <div className="w-full ">
            <div className="flex justify-between items-center">
              <div className="w-full bg-gray-400 h-6"></div>
              <div className="w-full bg-gray-400 h-6"></div>
            </div>
            <div className="w-full bg-gray-400 h-6 mt-3"></div>
            <div className="w-full bg-gray-400 h-6 mt-3"></div>
          </div>
          <div className="w-full bg-gray-400 h-6 mt-3"></div>
          <div className="w-full bg-gray-400 h-4 mt-3"></div>
          <div className="w-full bg-gray-400 h-6 mt-3"></div>
          <div className="w-full bg-gray-400 h-4 mt-3"></div>
          <div className="w-full bg-gray-400 h-6 mt-3"></div>
        </div>
        <div className="lg:w-[70%] w-full h-auto rounded-lg   ">
          <div className=" rounded-lg w-full  flex justify-between items-center gap-8">
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
          </div>
          <div className=" rounded-lg w-full  flex justify-between items-center gap-8 mt-4">
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
          </div>
          <div className=" rounded-lg w-full  flex justify-between items-center gap-8 mt-4">
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
          </div>
          <div className=" rounded-lg w-full  flex justify-between items-center gap-8 mt-4">
            <div className="h-7 w-full bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
            <div className="h-10 w-[60%] bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
          </div>
          <div className="h-10 w-[50%] bg-gray-400 rounded-sm border-[1px] border-gray-300"></div>
        </div>
      </div>
    </>
  );
};

const DelTourModal = ({ uponModal, setUponModal, navigate }) => (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center font-be-vietnam">
    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-xs">
      <div className="flex justify-center text-red-700 mb-4">
        <PiWarningCircleDuotone size={60} />
      </div>
      <p className="text-center tracking-wide text-lg">
        Are you sure to cancel this booking?
      </p>

      <div className="flex tracking-wide justify-center items-center space-x-4 mt-5">
        <button
          className="py-2 px-4 text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-200"
          onClick={() => setUponModal(false)}
        >
          Cancel
        </button>
        <button
          className="py-2 px-4 text-white bg-orange-500 rounded-lg hover:bg-orange-700"
          onClick={() => {
            toast.success("Booking canceled");
            localStorage.clear();
            navigate("/cab-list");
          }}
        >
          Yes, Cancel
        </button>
      </div>
    </div>
  </div>
);

import React, { useState } from "react";
import roomImg from "../../Assets/user-imgs/Home-imgs/Mask group 8.png";
import { useNavigate } from "react-router-dom";

const RoomSelectForm = ({ bookingDate, setBookingDate, hotelData }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    adultsCount: 0,
    childrenCount: 0,
  });
  const [selectMultiple, setSelectMultiple] = useState([]);
  const [error, setError] = useState("");
  const [msgData, setMsgData] = useState(
    "Click on a room to make your selection from the above list."
  );

  const handleClick = (index) => {
    setSelectMultiple((prev) => {
      const updatedSelection = prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index];

      // Update message dynamically based on room selection
      if (updatedSelection.length === 0) {
        setMsgData(
          "Click on a room to make your selection from the above list."
        );
      } else {
        const selectedRoomNames = updatedSelection
          .map((i) => hotelData.rooms[i]?.name)
          .join(", ");
        setMsgData(`Selected Rooms: ${selectedRoomNames}`);
      }

      return updatedSelection;
    });
  };

  const handleIncrement = (field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: prevData[field] + 1,
    }));
  };

  const handleDecrement = (field) => {
    if (formData[field] > 0) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: prevData[field] - 1,
      }));
    }
  };

  const handleFormSubmit = () => {
    if (!bookingDate) {
      setError("Please select a booking date.");
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (selectMultiple.length === 0) {
      setError("Please select at least one room.");
      setTimeout(() => setError(""), 3000);
      return;
    }
    if (formData.adultsCount === 0 && formData.childrenCount === 0) {
      setError("Please add at least one adult or child.");
      setTimeout(() => setError(""), 3000);
      return;
    }

    const bookingData = {
      bookingDate,
      selectedRooms: selectMultiple.map((index) => hotelData.rooms[index]),
      adultsCount: formData.adultsCount,
      childrenCount: formData.childrenCount,
      hotel: hotelData,
    };

    localStorage.setItem("bookingData", JSON.stringify(bookingData));
    navigate("/booking-form");
  };

  const isRoomAvailable = (room, date) => {
    return !room.availability.some(
      (item) => item.date.substring(0, 10) === date && !item.isAvailable
    );
  };

  return (
    <React.Fragment>
      <div className="lg:col-span-2 lg:mt-4 flex justify-start flex-col lg:pr-7 sm:px-4 lg:px-0">
        <div className="col-span-1">
          <div className="mb-4 w-full flex justify-center flex-col items-start lg:px-0">
            <h1 className="text-xl font-bold tracking-wide">
              Room Availability
            </h1>
            <p className="text-sm text-[#606060] text-justify tracking-wide">
              Your perfect stay awaits - check room availability now!
            </p>
          </div>

          <div className="text-lg font-semibold tracking-wide mb-2 capitalize">
            Pick your date
          </div>
          <div className="mb-8 w-full flex justify-center flex-col items-start">
            <input
              type="date"
              className="w-full border-2 text-sm rounded-md p-2 focus:outline-none focus:ring focus:border-blue-300 cursor-pointer"
              value={bookingDate}
              onChange={(e) => setBookingDate(e.target.value)}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 w-full mb-8">
            {hotelData?.rooms?.map((i, index) => {
              const available = isRoomAvailable(i, bookingDate);

              return (
                <div
                  key={i.roomNo}
                  className={`relative ${
                    available ? "" : "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => available && handleClick(index)}
                >
                  <div
                    className={`flex justify-center items-center flex-col p-3 cursor-pointer gap-2 rounded-md w-full ${
                      selectMultiple.includes(index)
                        ? "bg-[#85f758] border-[1px] border-[#43ed8d]"
                        : "bg-[#ECF4FF]"
                    }`}
                  >
                    <div className="flex justify-center items-center">
                      <img src={roomImg} alt="room img" className="h-5 w-5" />
                      <img src={roomImg} alt="room img" className="h-5 w-5" />
                    </div>
                    <div className="rounded-md w-full">
                      <div className="flex justify-center items-center">
                        <p className="xl:text-md text-md">
                          {i?.name?.substring(0, 3)?.toUpperCase()}
                        </p>
                      </div>
                    </div>
                  </div>

                  {selectMultiple.includes(index) && (
                    <div className="absolute top-1 right-1 w-5 h-5 bg-[#F7A358] rounded-full flex justify-center items-center text-white">
                      ✓
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="flex items-center bg-gray-100 p-3 rounded-md shadow-md text-gray-700 text-sm mb-6 tracking-wide">
            <span className="text-3xl mr-2">🏨</span>
            <span className="text-justify capitalize">{msgData}</span>
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="text-md font-semibold mb-2 tracking-wide capitalize">
              No. of Adults
            </div>
            <div className="flex items-center space-x-6">
              <button
                type="button"
                onClick={() => handleDecrement("adultsCount")}
                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
              >
                -
              </button>
              <div className="text-xl font-semibold text-gray-700 w-16 h-12 flex justify-center items-center border border-gray-300 rounded-md bg-gray-50">
                {formData.adultsCount}
              </div>
              <button
                type="button"
                onClick={() => handleIncrement("adultsCount")}
                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
              >
                +
              </button>
            </div>
          </div>

          <div className="flex justify-between items-center mb-6">
            <div className="text-md font-semibold mb-2 tracking-wide capitalize">
              No. of Children
            </div>
            <div className="flex items-center space-x-6">
              <button
                type="button"
                onClick={() => handleDecrement("childrenCount")}
                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
              >
                -
              </button>
              <div className="text-xl font-semibold text-gray-700 w-16 h-12 flex justify-center items-center border border-gray-300 rounded-md bg-gray-50">
                {formData.childrenCount}
              </div>
              <button
                type="button"
                onClick={() => handleIncrement("childrenCount")}
                className="w-8 h-8 flex justify-center items-center text-2xl rounded-full font-bold text-white bg-gradient-to-r from-[#FF5C00] to-[#FF9900] hover:scale-105 transition-all duration-300 shadow-md focus:outline-none"
              >
                +
              </button>
            </div>
          </div>

          {error && <p className="text-red-500 text-sm mb-6">{error}</p>}

          <div className="text-center">
            <button
              onClick={() => {
                window.scrollTo(0, 0);
                handleFormSubmit();
              }}
              className="w-full py-3 px-6 tracking-wide bg-[#F94E05] text-white font-semibold rounded-lg hover:bg-[#D24E02] transition duration-300"
            >
              Book Hotel For {bookingDate}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RoomSelectForm;

import React, { useEffect, useState } from "react";
import Cabshistory from "../../components/Cabs/Cabshistory";

const CabsBookingHistory = () => {
  const [activeCount, setActiveCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [todaysCount, setTodaysCount] = useState(0);
  const [finishedCount, setFinishedCount] = useState(0);
  const [canceledCount, setCanceledCount] = useState(0);

  return (
    <div className="sm:px-8 px-4 mt-8">
      <h1 className="text-3xl font-semibold mb-6">Cabs Bookings</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-4">
        <div className="bg-white p-6 rounded-lg shadow-md text-center col-span-1">
          <h3 className="text-xl font-medium">Todays Bookings</h3>
          <p className={`text-2xl font-semibold text-gray-600 mt-2`}>
            {todaysCount}
          </p>
        </div>
        {/* <div className="bg-white p-6 rounded-lg shadow-md text-center col-span-1">
          <h3 className="text-xl font-medium">Total Bookings</h3>
          <p className={`text-2xl font-semibold text-yellow-600 mt-2`}>
            {totalCount}
          </p>
        </div> */}
        <div className="bg-white p-6 rounded-lg shadow-md text-center col-span-1">
          <h3 className="text-xl font-medium">Active Bookings</h3>
          <p className={`text-2xl font-semibold text-green-600 mt-2`}>
            {activeCount}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center col-span-1">
          <h3 className="text-xl font-medium">Finished Bookings</h3>
          <p className={`text-2xl font-semibold text-blue-600 mt-2`}>
            {finishedCount}
          </p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center col-span-1">
          <h3 className="text-xl font-medium">Canceled Bookings</h3>
          <p className={`text-2xl font-semibold text-red-600 mt-2`}>
            {canceledCount}
          </p>
        </div>
      </div>

      <div className="text-2xl font-semibold leading-tight mb-4">
        Booked Client History
      </div>

      <Cabshistory
        activeCount={activeCount}
        totalCount={totalCount}
        todaysCount={todaysCount}
        finishedCount={finishedCount}
        canceledCount={canceledCount}
        setActiveCount={setActiveCount}
        setFinishedCount={setFinishedCount}
        setTodaysCount={setTodaysCount}
        setTotalCount={setTotalCount}
        setCanceledCount={setCanceledCount}
      />
    </div>
  );
};

export default CabsBookingHistory;

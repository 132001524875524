import React from "react";
import { GoDotFill } from "react-icons/go";
import termAndConditions from "../../Assets/Logos/termAndConditions.png";

export default function TermsAndConditions() {
  const terms = [
    "Hotel can be changed as per their availability.",
    "Cab wouldn't be driven after 8:00 PM, in case required then govt guidelines will be followed.",
    "Extra stay wouldn't be booked at any reason after departure.",
    "A.C wouldn't be working after Haridwar. In case guest want to on that this will be chargeable.",
    "Guest will be responsible for Any Losses i.e. Luggage Money etc.",
    "Advance amount will be paid for Helicopter booking and @1000/ per ticket will be charged.",
    "It will be the guest's own responsibility to transport the luggage to and from the vehicle.",
    "No breakfast will be provided till 5:00 Am.",
    "In case any technical issue with vehicle then guest need to cooperate until the issue resolved.",
    "Vehicle can be arranged in case big issue in the vehicle and it will take 5-6hrs.",
    "Always follow the timing.",
    "Payment will be acceptable only mentioned details or by cash.",
    "No refund will be given in case of missed or unused services. This includes Flights, Train, Hotel Stay, Meals, Sightseeing, Transfers, Entry Ticket, Permit Or any other services.",
    "In case client are suspected to indulge in any kind of illegal activity or violent behavior, Weekend Bhraman Tour Planner will immediately stop the services while holding all the refunds.",
  ];

  return (
    <div>
      <React.Fragment>
        <div className="flex-grow relative">
          <div className="relative">
            <img
              className="w-full h-auto"
              src={termAndConditions}
              alt="Tourimg"
            />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
              Terms & Conditions
            </div>
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="w-full mx-auto bg-white px-4 md:px-12 xl:px-20 py-7">
          <h1 className=" font-bold mb-6 text-4xl">Terms & Conditions</h1>

          <div className="space-y-3">
            {terms.map((term, index) => (
              <div key={index} className="flex items-start">
                <span className="text-gray-800 mr-2">•</span>
                <p className="text-gray-700 text-lg">{term}</p>
              </div>
            ))}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}

import React from "react";
import { FaMapMarkerAlt, FaPhone, FaEnvelopeOpen } from "react-icons/fa";
import { SiRazorpay } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import FooterImg from "../../Assets/Common/FooterImg.png";

const Footer = () => {
  const navigate = useNavigate();

  const uLinks = [
    { name: "Terms and Condition", link: "/terms-and-condition" },
    { name: "Privecy Policy", link: "/privacy-policy" },
    { name: "Refund Policy", link: "/refund-policy" },
    { name: "Contact", link: "/contact" },
    { name: "Destinations", link: "/destinations" },
  ];

  return (
    <React.Fragment>
      <img
        src={FooterImg}
        alt="FooterImg"
        className="w-full h-auto shadow-md"
      />

      <footer className="bg-[#151414] text-gray-400">
        <div className="mx-4 sm:mx-6 md:mx-16 py-8">
          {/* Responsive 3-Column Layout */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
            {/* Column 1: Find Us */}
            <div>
              <h4 className="text-white text-lg font-semibold tracking-wider mb-4">
                Find Us
              </h4>
              <div className="flex items-start space-x-4 mb-6">
                <FaMapMarkerAlt className="text-orange-500 text-xl" />
                <div className="tracking-wide">
                  <p className="text-md">
                    <span className="font-bold text-white">Head Office: </span>
                    A-37 Sachdeva Complex, Madhu Vihar I.P Ext. New-Delhi-92
                  </p>
                  <p className="text-md mt-2">
                    <span className="font-bold text-white">
                      Branch Office:{" "}
                    </span>
                    Dudhadhari Chowk, Bhupatwala, Haridwar-249410
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4 mb-6">
                <FaPhone className="text-orange-500 text-xl rotate-90" />
                <div>
                  <p className="text-md">
                    <span className="text-orange-500 mr-2">●</span>
                    +91-8178958591, +91-9310366002
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <FaEnvelopeOpen className="text-orange-500 text-xl" />
                <p className="text-md">
                  <span className="text-orange-500 mr-2">●</span>
                  info@weekendbhraman.com
                </p>
              </div>
            </div>

            {/* Column 2: Useful Links */}
            <div>
              <h4 className="text-white text-lg font-semibold tracking-wider mb-4">
                Useful Links
              </h4>
              <ul className="space-y-4">
                {uLinks.map((item) => (
                  <li key={item.name}>
                    <div
                      onClick={() => {
                        navigate(item.link);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      className="hover:text-[#F88F26] tracking-wide cursor-pointer text-sm tracking-wide transition duration-200 ease-in-out"
                    >
                      {item.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/* Column 3: Certifications */}
            <div>
              <h4 className="text-red-500 text-md font-semibold tracking-wider mb-4">
                Certifications
              </h4>
              <p className="tracking-wide mb-4">GSTIN-07CCTPN4212Q1Z</p>
              <p className="tracking-wide mb-4">
                Registered by Govt of India MSME
              </p>
              <p className="tracking-wide font-bold">UDYAM-DL-11-0064056</p>
              <div className="mt-6">
                <h4 className="text-white text-lg font-semibold mb-2">
                  Secure Transactions with
                </h4>
                <div className="flex items-center gap-2">
                  <SiRazorpay className="text-3xl" color="#31a2f9" />
                  <span className="text-[#31a2f9] text-lg">Razorpay</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="bg-[#202020] py-4 px-6">
          <div className="container mx-auto flex flex-col md:flex-row justify-between items-center text-sm">
            <div className="text-center md:text-left mb-2 md:mb-0">
              <p className="text-white">
                © {new Date().getFullYear()}. All Rights Reserved.{" "}
                <span className="text-[#F88F26] font-semibold">
                  Weekend Bhraman - V 2.0.0
                </span>
              </p>
            </div>
            <div className="text-center md:text-right">
              <p>
                Design & Development by{" "}
                <a
                  href="https://www.webbocket.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold text-[#F88F26]"
                >
                  Web_Bocket Pvt. Ltd.
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
